<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar                         
            v-on:toolbar-button-click="onToolbarClick"  
        > 
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <div
            v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)" 
            class="white-panel-items"
        >
            <ExtendedFilterPanel
                v-bind:value.sync="extendedTableFilter"
            />
        </div>

        <v-row no-gutters>
            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="entities"
                    :item-class="itemRowBackground"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })"
                    show-expand
                    @item-expanded="itemExpanded"
                    :expanded.sync="expanded"
                    >

                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
                        <v-btn 
                            icon
                            x-small
                            plain
                            class="expand-cust-btn"
                            @click="expand(true)" 
                            v-if="!isExpanded"
                        >
                            <v-icon>
                                fas fa-caret-right
                            </v-icon>
                        </v-btn>
                        <v-btn 
                            icon
                            x-small
                            plain
                            class="expand-cust-btn"
                            @click="expand(false)" 
                            v-else
                        >
                            <v-icon>
                                fas fa-caret-down
                            </v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <div class="table-expanded-wrapper">

                                <template v-if="item._info">
                                    <v-treeview 
                                        dense
                                        :items="item._info"
                                        class="cust-treeview-in-list"
                                        :open.sync="open[item.id]"
                                        >
                                        <template v-slot:label="{ item }">

                                            <template v-if="item.text=='Link'">
                                                    
                                                <div class="d-flex align-center">
                                                    <span class="linked-docs-title">{{ $t(GetLinkedTypes[item.type]) }}</span>
                                                    <div class="onlyReadData more-per-lab-wrap">
                                                        <v-doc-chip
                                                            v-for="(doc, index) in item.values"
                                                            :key="item.type+index"
                                                            :id="doc.Values[6]"
                                                            :link-id="doc.Values[0]"
                                                            :name="$t(GetDocumentTypes[doc.Values[9]]) + ' № ' + doc.Values[2] + ` ${$t('от_время')} ` + doc.Values[3] + (doc.Values[5] ? ' (' + doc.Values[5] + ')' : '')" 
                                                            iconColor="#7289DA"
                                                            :is-linked-document="true"
                                                            :source-document-id="item.sourceDocumentId"
                                                        />
                                                    </div>
                                                </div>

                                            </template>
                                            <template v-else-if="item.text=='Notify'">
                                                <div class="d-flex ctil-row-wrap">
                                                    <div class="flex-grow-1">
                                                        <div class="elipsis-text-wrap">
                                                            <span>
                                                                {{ $t("Уведомление") }}
                                                            </span>
                                                            <span>
                                                                {{ item.Values[0] }}
                                                            </span>
                                                            <span>
                                                                <v-employee-chip :id="item.Values[3]" :name="item.Values[2]" /> 
                                                            </span>
                                                            <div class="etw-text-container" v-tooltip.top-center="item.Values[1]">
                                                                <span class="etw-text">
                                                                    {{ item.Values[1] }}
                                                                </span>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>                                           
                                            </template>
                                            <template v-else-if="item.text=='0'">
                                                <div class="d-flex ctil-row-wrap">
                                                    <div class="d-flex align-center flex-grow-1">
                                                        <div class="elipsis-text-wrap">
                                                            <span 
                                                                v-if="item.Values[14] == 'True'"
                                                                :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                            >
                                                                {{ $t("Резолюция") }}
                                                            </span>
                                                            <span 
                                                                v-else
                                                                :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                            >
                                                                {{ $t("Внешняя") }}
                                                            </span>
                                                            
                                                            <span :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''">
                                                                {{ item.Values[8] }}
                                                            </span>

                                                            <v-employee-chip v-if="item.Values[14] == 'True'" :id="item.Values[7]" :name="item.Values[1]" :is-employee="true" />
                                                            <v-enterprise-chip v-else :name="item.Values[1]" />

                                                            <i v-if="item.Values[13] === 'True'" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                                            <div class="etw-text-container" v-tooltip.top-center="item.Values[2]">
                                                                <span :class="[item.Values[5] == 7 ? 'text-decoration-line-through' : '', 'etw-text']">
                                                                    {{ item.Values[2] }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                        <span 
                                                            v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')"
                                                            :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                        >
                                                            {{ dateFormat(item.Values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                        <div class="onlyReadData more-per-lab-wrap"> 
                                                            <StatusChip
                                                                v-if="item.Values[14] == 'False' && item.hasExecutions"
                                                                :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1', 10))]"
                                                            />

                                                            <template v-else-if="item.Values[14] == 'True'">
                                                                <StatusChip
                                                                    v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss') && getDaysDiff(item.Values[4]) > -1 && item.Values[5] == '5'"
                                                                    :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                                                                />
                                                                <StatusChip
                                                                    v-else
                                                                    :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1'), 10)]"
                                                                />

                                                            </template>
                                                            
                                                            <StatusChip v-if="item.Values[19] || null" :status="avaibleStatuses[$options.rssStatusKeys[parseInt(item.Values[19] || '0')]]" />                    
                                                        </div>
                                                    </div>
                                                </div>                                              
                                            </template>
                                            <template v-else-if="item.text=='1'">
                                                <div class="d-flex ctil-row-wrap">
                                                    <div class="d-flex align-center flex-grow-1">
                                                        <div class="elipsis-text-wrap">
                                                            <span>{{$t("Исполнитель")}}</span>
                                                            <v-enterprise-chip
                                                                v-if="item.Values[9] === '00000000-0000-0000-0000-000000000000'"
                                                                :name="item.Values[1]"
                                                                :iconColor="executerStatusColor(item.Values[8])"
                                                                :main="item.Values[6] == 'True'"
                                                            />
                                                            <v-workplace-chip
                                                                v-else
                                                                :id="item.Values[9]"
                                                                :name="item.Values[0]"
                                                                :iconColor="executerStatusColor(item.Values[8])"
                                                                :main="item.Values[6] == 'True'"
                                                                :is-employee="true"
                                                            />

                                                            <div class="etw-text-container" v-tooltip.top-center="item.Values[5]">
                                                                <span class="etw-text">
                                                                    {{ item.Values[5] }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                        <span v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')">
                                                            {{ dateFormat(item.Values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                        <StatusChip :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[2] || '-1', 10))]" />
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else-if="item.text=='2'">
                                                <div class="d-flex ctil-row-wrap">
                                                    <div class="d-flex align-center flex-grow-1">
                                                        <div class="elipsis-text-wrap">
                                                            <span>
                                                                {{ $t("Пункт") }} № {{ item.Values[15] }}
                                                            </span>
                                                            
                                                            <span>
                                                                {{ item.Values[8] }}
                                                            </span>

                                                            <v-employee-chip v-if="item.Values[14] == 'True'" :id="item.Values[7]" :name="item.Values[1]" :is-employee="true" />
                                                            <v-enterprise-chip v-else :name="item.Values[1]" />

                                                            <i v-if="item.Values[13] === 'True'" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                                            <div class="etw-text-container" v-tooltip.top-center="item.Values[2]">
                                                                <span class="etw-text">
                                                                    {{ item.Values[2] }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-center flex-grow-0"  style="width:110px;">
                                                        <span v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')">
                                                            {{ dateFormat(item.Values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                        <div class="onlyReadData more-per-lab-wrap"> 
                                                            <StatusChip
                                                                v-if="item.Values[14] == 'False' && item.hasExecutions"
                                                                :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1', 10))]"
                                                            />

                                                            <template v-else-if="item.Values[14] == 'True'">
                                                                <StatusChip
                                                                    v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss') && getDaysDiff(item.Values[4]) > -1 && item.Values[5] == '5'"
                                                                    :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                                                                />
                                                                <StatusChip
                                                                    v-else
                                                                    :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1'), 10)]"
                                                                />

                                                            </template>
                                                            
                                                            <StatusChip v-if="item.Values[19] || null" :status="avaibleStatuses[$options.rssStatusKeys[parseInt(item.Values[19] || '0')]]" />                    
                                                        </div>
                                                    </div>
                                                </div>        
                                            </template>                                      
                                            <template v-else>
                                                {{ getLabel(item) }}
                                            </template>
                                            
                                        </template>
                                    </v-treeview>
                                </template>
                                <v-progress-linear v-else
                                    color="#a94442"
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>

                            </div>
                        </td>
                    </template>

                    <template v-slot:[`item.regdate`]="{ item }">
                        <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                    </template>
                    
                    <template v-slot:[`item.theme`]="{ item }">
                        <div class="elipsis-text-wrap">           
                            <v-common-chip v-if="$helpers.getCommonStatusForList(item)" :document-type="item.doctypeof"/>             
                            <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.theme">
                                    {{item.theme}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.authorname`]="{ item }">
                        <div class="elipsis-text-wrap">
                            <v-employee-chip :id="item.authorid" :name="item.authorname" />
                        </div>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>

        <InnerExFilterDlg             
            v-model="extendedTableFilter" 
            ref="InnerExFilterDlgRef" 
        />

    </div>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import _ from 'lodash';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"
import adapter from "@/services/adapter";
import { mapActions, mapGetters } from 'vuex';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import InnerExFilterDlg from '@/components/documents/dialogs/extended-filters/Inners.vue';
import Toolbar from '@/components/documents/Toolbar.vue';

export default {
    name: "Inners",
    rssStatusKeys:["", "rssOnSign", "rssSignedByChief", "rssSended"],
    columnIndex: {
        'regnumber': 2,
        'regdate': 4,
        'correspondent': 5,
        'theme': 6,
        'authorname': 12
    },
    components: {
        SearchPanel,
        ExtendedFilterPanel,
        StatusChip,
        InnerExFilterDlg,
        Toolbar
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,

            dataSource: null,

            total: 0,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,

            expanded: [],
            open: {}
        }
    },
    computed: {
        ...mapGetters('documents/references', ['GetLinkedTypes', 'GetDocumentTypes']),
        headers() {
            return [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'},
                { text: i18n.t("Автор"), value: 'authorname', width: '150px'},
                { text: i18n.t("Статус"), value: '', width: '150px', sortable: false, class: 'status-col'}
            ]
        },
        options: {
            get: function() {
                return this.$store.getters['edslight/inners/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('edslight/inners/SET_TABLE_OPTION', newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['edslight/inners/getSearch'];
            },
            set: _.debounce(function(newValue) {
                this.resetPage();
                this.$store.commit('edslight/inners/SET_SEARCH', newValue);
            }, 250),
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['edslight/inners/getExtendedFilter'];
            },
            set: function(newValue) {
                this.resetPage();
                this.$store.commit('edslight/inners/SET_EXTENDED_FILTER', newValue);
            }
        },
        collection () {
            return "Inners";
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        },
        isSignerControlTooltip () {
            return this.$t("Личный_контроль");
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async itemExpanded(val) {

            if (!val.value)
                return;
            
            this.$set(val.item, '_info', null)

            let response = await httpAPI({
                url: `api/correspondence/getdocumentchildren?id=${val.item.id}`,
                method: 'GET',               
                headers: { 'isCommon': val.item.iscommon }
            });
            
            let data = response?.data?.Payload?.Data?.Object?.data;
            
            if (data)
            {
                let treeData = adapter.transformTreeData(null, data, val.item.id);
                this.$set(val.item, '_info', treeData)
            }   
        },
        getLabel(item){
            return item.Values.toString();
        },
        resetPage() {
            this.$store.commit('edslight/inners/SET_TABLE_OPTION_PAGE', 1);
        },
        itemRowBackground (item) {
            return item.readed === true ? '' : 'font-weight-bold';
        },        
        async getData (isRefresh = false) {
            this.$eventBus.$emit('update_edslight_counters');
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
            
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/edslight/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;
            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;
            return { SearchStr, DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument", DocumentTypeC4: "0", OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData };
        },
        dateFormat: sys.dateFormat,
        getDaysDiff(controlDate) {
            let diff = this.$moment(controlDate,'DD.MM.YYYY HH:mm:ss').diff(this.$moment(Date.now()), 'days');
            return diff;
        },
        executerStatusColor(item) {
            switch(item)
            {
                case 'success':
                    return "#43B581";
                case 'warning':
                    return "#FAA61A";
                case 'danger':
                    return "#F04747";
                default:
                    return null;
            }
        },
        async oneStatusColWidth() {
            var parent = document.getElementsByClassName('content-box')[0];
            var status_col_obj = parent.getElementsByClassName('status-col');
            var status_dropdown_col_obj = parent.getElementsByClassName('status-dropdown-col');
            var status_col_w = status_col_obj.length > 0 ? status_col_obj[0].clientWidth : 0;
            if(status_dropdown_col_obj.length > 0){
                for (var index = 0; index < status_dropdown_col_obj.length; index++) {
                    status_dropdown_col_obj[index].style.width = status_col_w + "px";
                }
            }
        },
        async onFilterClick() {
            try
            {
                await this.$refs.InnerExFilterDlgRef.open();
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async loadButtons() {
            this.setOverlayVisible({ visible: true });
            
            let response = await httpAPI({
                url: `api/actions/collection?type=Documents.Correspondence`,
                method: 'GET'
            });

            if (response) {
                this.dataSource = response.data.Payload;
                this.$store.commit('documents/toolbar/SET_MENU', {menu: this.dataSource.Data.Menu });
            }
            
            this.setOverlayVisible({ visible: false });
        },
        onToolbarClick(event, btn) {
            let method = this[btn.Action];

            if (method)
                method(btn);
            else
                this.$notify.alert(`Действие ${btn.Action} не реализовано`);
        },
        async Refresh() {
            this.getData(true);
        },
        async Export() {
            let filter = this.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;
            let exportURI = `api/download/export?param=${JSON.stringify(filter)}`;

            let text = i18n.t('Экспорт_списка_внутренних_документов_от_formatedDateTime.message', { formatedDateTime: this.$moment().format('DD.MM.YYYY HH.mm.ss') });

            this.$eventBus.$emit('on-get-report', {url: exportURI, text: text, type: 'Xls' });
        },
    },
    mounted() {
        this.$nextTick(this.oneStatusColWidth);
        window.addEventListener('resize', this.oneStatusColWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.oneStatusColWidth);
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
        open: {
            handler() {
                this.$nextTick(this.oneStatusColWidth);
            },
            deep: true,
        }
    },
    async created() {
        this.loadButtons();
    }
}
</script>